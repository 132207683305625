import Chart from "react-apexcharts";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { CardWhite } from "../../../../components/layout/CardWhite";
import { ApexOptions } from "apexcharts";
import { colors } from "../../../../constants/colors";
import { GreyCard } from "../greyCard";
import CHART_DATA from "../../../../config/dailyChart";
import moment from "moment";
import HASHRATE from "../../../../config/pplns";
import GENERAL from "../../../../config/general8888";
import GENERAL2 from "../../../../config/general7000";

const defaultChart = CHART_DATA.reverse();
const series1: ApexOptions["series"] = [
  {
    name: "daily",
    data: defaultChart.map((slice: any) => ({
      x: moment.unix(slice.sliceTime).format("DD.MM"),
      y: (+slice.hashRate / 1000000000000).toFixed(0),
    })),
  },
];

export const Statistic: FC<{
  dailyChart: any;
  blockDifficulty?: number;
  blockHeight: string;
}> = ({ dailyChart, blockDifficulty, blockHeight }) => {
  const dataBot = [
    {
      name: "Block height",
      value: blockHeight || HASHRATE.blockHeight,
    },
    {
      name: "Block difficulty",
      value: `${(
        (blockDifficulty && blockDifficulty / 1000000000000) ||
        GENERAL.bitcoin.blockDifficulty / 1000000000000
      ).toFixed(0)} T`,
    },
    {
      name: "Hash rate / H",
      value: `${(+GENERAL2.content.hashRate1H / 1000000000000).toFixed(
        0
      )} TH/s`,
    },
    {
      name: "Hash rate / D",
      value: `${(+GENERAL2.content.hashRate1D / 1000000000000).toFixed(
        0
      )} TH/s`,
    },
  ];

  const [series, setSeries] = useState(series1);
  useEffect(() => {
    if (dailyChart?.length > 0) {
      const series2: ApexOptions["series"] = [
        {
          name: "daily",
          data: dailyChart
            .reverse()
            .splice(0, 10)
            .map((slice: any) => ({
              x: moment.unix(slice.sliceTime).format("DD.MM"),
              y: (+slice.hashRate / 1000000000000).toFixed(0),
            })),
        },
      ];
      setSeries(series2);
    } else {
      setSeries(series1);
    }
  }, [dailyChart]);

  const options: ApexOptions = {
    chart: {
      toolbar: { show: false },
      fontFamily: "Inter",
      group: "social",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.5,
        opacityFrom: 0.6,
        opacityTo: 0.2,
        stops: [0, 50, 100],
        gradientToColors: ["#0070E4", "#cce2fa"],
      },
    },
    colors: ["#0070E4"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
      colors: ["#0070E4"],
    },
    markers: {
      size: 4,
      strokeWidth: 0,
      hover: {
        size: 6,
      },
    },

    yaxis: {
      crosshairs: {
        show: true,
        stroke: { color: "transparent" },
      },
      tooltip: {
        enabled: false,
      },
      opposite: false,
      axisBorder: {
        color: "rgba(0, 0, 0, 0.05)",
        show: false,
      },
      labels: {
        align: "left",
        offsetX: 0,
        style: {
          colors: "#797979",
          fontSize: "12px",
        },
      },
    },
    xaxis: {
      type: "category",
      // type:'datetime',
      // tickPlacement: "on",
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: "rgba(0, 0, 0, 0.05)",
        show: true,
      },
      labels: {
        datetimeUTC: true,
        style: {
          colors: "#797979",
          fontSize: "12px",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      custom: function ({
        series: seriesChart,
        seriesIndex,
        dataPointIndex,
        w,
      }) {
        return null;
      },
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "rgba(30, 16, 16, 0.05)",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };

  return (
    <div style={{ position: "relative" }}>
      <Anhcor id="#statistic" />
      <CardWhite title="Statistics Solomine ">
        <GreyCard title="Pool speed">
          <ChartWrapper id="statistic_chart">
            <Chart options={options} series={series} type="area" height={200} />
          </ChartWrapper>
          <Blocks>
            {dataBot.map((block) => (
              <Block key={block.name}>
                <BlockTitle>{block.name}</BlockTitle>
                <BlockValue>{block.value}</BlockValue>
              </Block>
            ))}
          </Blocks>
        </GreyCard>
      </CardWhite>
    </div>
  );
};

const ChartWrapper = styled.div``;

const Anhcor = styled.div`
  position: absolute;
  top: -100px;
`;
const Blocks = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  @media (min-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;
const Block = styled.div`
  padding: 16px;
  background: rgba(10, 10, 10, 0.03);
  border-radius: 12px;
`;
const BlockTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.gray};
`;
const BlockValue = styled.div`
  margin-top: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
`;
