// @ts-nocheck
import { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { colors } from "../../../../constants/colors";
import { CODE } from "../../../../constants/img";
import { Button } from "../../../../components/buttons";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../config";

const symbols = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];

const randomIntFromInterval = (min, max) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const TitleBlock: FC = () => {
  const matrix = useRef(null);
  function r(from, to) {
    return ~~(Math.random() * (to - from + 1) + from);
  }
  // function pick() {
  //   return arguments[r(0, arguments.length - 1)];
  // }
  function getChar() {
    return symbols[randomIntFromInterval(1, symbols.length)];
    // return String.fromCharCode(
    //   pick(r(0x3041, 0x30ff), r(0x2000, 0x206f), r(0x0020, 0x003f))
    // );
  }
  function loop(fn, delay) {
    let stamp = Date.now();
    function _loop() {
      if (Date.now() - stamp >= delay) {
        fn();
        stamp = Date.now();
      }
      requestAnimationFrame(_loop);
    }
    requestAnimationFrame(_loop);
  }
  class Char {
    constructor() {
      this.element = document.createElement("span");
      this.mutate();
    }
    mutate() {
      this.element.textContent = getChar();
    }
  }
  class Trail {
    constructor(list = [], options) {
      this.list = list;
      this.options = Object.assign({ size: 10, offset: 0 }, options);
      this.body = [];
      this.move();
    }
    traverse(fn) {
      this.body.forEach((n, i) => {
        let last = i == this.body.length - 1;
        if (n) fn(n, i, last);
      });
    }
    move() {
      this.body = [];
      let { offset, size } = this.options;
      for (let i = 0; i < size; ++i) {
        let item = this.list[offset + i - size + 1];
        this.body.push(item);
      }
      this.options.offset = (offset + 1) % (this.list.length + size - 1);
    }
  }
  class Rain {
    constructor({ target, row }) {
      this.element = document.createElement("p");
      this.build(row);
      if (target) {
        target.appendChild(this.element);
      }
      this.drop();
    }
    build(row = 20) {
      let root = document.createDocumentFragment();
      let chars = [];
      for (let i = 0; i < row; ++i) {
        let c = new Char();
        root.appendChild(c.element);
        chars.push(c);
        if (Math.random() < 0.5) {
          loop(() => c.mutate(), r(1e3, 5e3));
        }
      }
      this.trail = new Trail(chars, {
        size: r(10, 30),
        offset: r(0, 100),
      });
      this.element.appendChild(root);
    }
    drop() {
      let trail = this.trail;
      let len = trail.body.length;
      let delay = r(10, 100);
      loop(() => {
        trail.move();
        trail.traverse((c, i, last) => {
          c.element.style = `
          color:rgba(255,255,255,0.${i})
        `;

          if (last) {
            c.mutate();
            c.element.style = `
            color: rgba(255,255,255,0.2);
            text-shadow:
              0 0 .5em #fff,
              0 0 .5em currentColor;
          `;
          }
        });
      }, delay);
    }
  }

  useEffect(() => {
    if (matrix.current) {
      for (let i = 0; i < 50; ++i) {
        new Rain({ target: matrix.current, row: 50 });
      }
    }
  }, [Rain, matrix]);
  return (
    <Wrapper>
      <Inner>
        {/* <Matrix>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </Matrix> */}
        <Matrix ref={matrix} />
        <Content>
          <Title>Solomine – is a revolutionary mining blockchain-pool</Title>
          <Description>
            Join us and experience the benefits of the new fairer business
            model.
          </Description>
          <Link to={ROUTES.JOIN}>
            <Button variant="white">Join to Pool</Button>
          </Link>
        </Content>
      </Inner>
    </Wrapper>
  );
};

const Matrix = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  opacity: 0.2;
  left: 0;

  & p {
    line-height: 1;
  }
  & span {
    display: block;
    width: 2vmax;
    height: 2vmax;
    font-size: 1.35vmax;
    color: #fff;
    text-align: center;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
  }
`;
const Wrapper = styled.div`
  padding: 0 8px;
  @media (min-width: 767px) {
    padding: 0 16px;
  }
`;
const Inner = styled.div`
  padding: 104px 46px 45px;
  background: url("${CODE}"), linear-gradient(90deg, #4e555b 0%, #767d83 100%);
  border-radius: 0px 0px 24px 24px;
  position: relative;
  overflow: hidden;
  @media (min-width: 767px) {
    padding: 192px 0 112px;
  }
  @media (min-width: 1440px) {
    border-radius: 0px 0px 32px 32px;
  }
`;
const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 656px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: ${colors.white};
  @media (min-width: 767px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (min-width: 1440px) {
    font-size: 48px;
    line-height: 48px;
  }
`;
const Description = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 144%;
  color: ${colors.alpha6};
  margin-top: 16px;
  margin-bottom: 32px;
  @media (min-width: 767px) {
    margin-top: 24px;
    margin-bottom: 48px;
  }
`;
