export const joinConfig = [
  {
    text: "To join Bitcoin generation in our pool, you need to connect your miner to the Stratum interface, which is available at",
    description: "stratum+tcp://31.220.79.244:3333",
  },
  {
    text: "The payout address should be used as a login. You can also add a worker name like this:",
    description: " YOUR_BTC_ADDRESS.RIG_ID.",
  },
  {
    text: "TYour payout address. Create a new one with Bitcoin Core or Electrum, or use one issued by the exchange.",
    description: "Example: 3LnEAZj28gLnPTAnnCFN8uT2bcKcuVQdQU",
  },
];