// http://80.249.131.181:8888/pplns
export default {
    "workSequence": 304064,
    "blockHeight": 812166,
    "checkSum": "8213",
    "workShares": [
        [
            "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "9911845788"
        ],
        [
            "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "11301149439"
        ]
    ],
    "workTotal": "21212995227"
}