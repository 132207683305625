import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";

type ButtonProps = {
  children: string;
  variant?: "blue" | "white";
  size?: "small" | "medium";
  fullWidth?: boolean;
};

export const Button: FC<ButtonProps> = ({
  children,
  variant = "blue",
  size = "medium",
  fullWidth,
}) => {
  return (
    <ButtonStyle fullWidth={fullWidth} size={size} variant={variant}>
      {children}
    </ButtonStyle>
  );
};

const ButtonStyle = styled.button<
  Pick<ButtonProps, "variant" | "size" | "fullWidth">
>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ size }) => (size === "medium" ? "20px 40px" : "7px 20px")};
  background: ${({ variant }) =>
    variant === "blue" ? colors.blue : colors.white};
  color: ${({ variant }) => (variant === "blue" ? colors.white : colors.dark)};
  border-radius: 64px;
  transition: 0.2s;
  &:hover {
    background: ${({ variant }) =>
      variant === "blue" ? colors.blueLight : colors.blue};
    color: ${colors.white};
  }
  font-size: ${({ size }) => (size === "medium" ? "20px" : "16px")};
`;
