// http://80.249.131.181:8888/snapshot/4c408cf97ee96ffa4847a921bbc835658ea81fa5
export default {
    "snapshotVersion": "00100000",
    "snapshotTime": 1697299509,
    "snapshotSequence": 304059,
    "snapshotHash": "4c408cf97ee96ffa4847a921bbc835658ea81fa5",
    "parentSnapshotHash": "668a4c5c23ded3ca61ccfb196a6303b5eb82cef0",
    "snapshotDifficulty": 3051600.8852598243,
    "Solution": {
        "extraNonce1": "0020",
        "extraNonce2": "00000013ef12f1eb",
        "nonce": "2ed2d9fd",
        "difficulty": 30516.134203009762,
        "bits": "1b0225c6",
        "timestamp": 1697299509,
        "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
        "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
        "pplnsSequence": 304054,
        "pplnsChecksum": "9d3b",
        "indirectPayout": true,
        "versionBits": "00000000",
        "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
        "workId": "f9b412214e7145a0b24734affd902da01cdc69e4"
    },
    "Shares": [
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000d626ff1eb",
            "nonce": "1973cc24",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299444,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "07ffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "6536183444f680c469cb76d3541c3cec04aeb37a"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000da398f1eb",
            "nonce": "17662359",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299450,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "0fffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "f4cfb76d9212ac916815203bb031e11e8daab5ae"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000005640c28",
            "nonce": "2279d040",
            "difficulty": 70500.68548916612,
            "bits": "1b00edf8",
            "timestamp": 1697299463,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "1fff0000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "f4cfb76d9212ac916815203bb031e11e8daab5ae"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000e054ff1eb",
            "nonce": "67aee871",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299450,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "0fffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "f4cfb76d9212ac916815203bb031e11e8daab5ae"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000e6cfef1eb",
            "nonce": "7ce38180",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299454,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "0fffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "34aa9ee0178d47e3c056e7922f76d7b1de68a4cd"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000007380c28",
            "nonce": "25fe0cd9",
            "difficulty": 70500.68548916612,
            "bits": "1b00edf8",
            "timestamp": 1697299486,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "0000e000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "34aa9ee0178d47e3c056e7922f76d7b1de68a4cd"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000e9900f1eb",
            "nonce": "23d9a8da",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299459,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "17ffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "f033ed2918418bb33a573dbd8b5d2350f22f9497"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000ec1c9f1eb",
            "nonce": "fe00f54e",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299459,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "17ffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "f033ed2918418bb33a573dbd8b5d2350f22f9497"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "0000000007abbf0b",
            "nonce": "c853292c",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299461,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "00142000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "ff3dd29fdea9a0512c5634b62ec14ac1f0c36fcb"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000f52d8f1eb",
            "nonce": "5a0e489b",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299464,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "17ffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "031e65c73c8e56eb062998ef20d0040c6b671611"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "00000000096fbf0b",
            "nonce": "97abfe00",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299466,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "00096000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "07f0f20156ad01a2c04ca18b9c87c448a4b7b1a4"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "0000000009b5bf0b",
            "nonce": "4fdf6aa6",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299466,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "0015e000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "07f0f20156ad01a2c04ca18b9c87c448a4b7b1a4"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000f7ffcf1eb",
            "nonce": "8ebca2bf",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299464,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "0fffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "031e65c73c8e56eb062998ef20d0040c6b671611"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000f8a67f1eb",
            "nonce": "b388be2f",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299464,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "07ffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "031e65c73c8e56eb062998ef20d0040c6b671611"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "000000000eeabf0b",
            "nonce": "1b59d505",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299466,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "0015e000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "07f0f20156ad01a2c04ca18b9c87c448a4b7b1a4"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000fb5eff1eb",
            "nonce": "691e6205",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299469,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "07ffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "9ae8e79e3f5dca1c4ba7b56ebc945bae30618211"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000002a00c28",
            "nonce": "184182a6",
            "difficulty": 70500.68548916612,
            "bits": "1b00edf8",
            "timestamp": 1697299474,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "1fff0000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "9ae8e79e3f5dca1c4ba7b56ebc945bae30618211"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000000fd3cbf1eb",
            "nonce": "8a509dc5",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299469,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "07ffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "9ae8e79e3f5dca1c4ba7b56ebc945bae30618211"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "000000100253f1eb",
            "nonce": "4683e1d2",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299469,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "0fffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "9ae8e79e3f5dca1c4ba7b56ebc945bae30618211"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "000000000a2c0c28",
            "nonce": "0f207245",
            "difficulty": 70500.68548916612,
            "bits": "1b00edf8",
            "timestamp": 1697299486,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "0000e000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "9ae8e79e3f5dca1c4ba7b56ebc945bae30618211"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "0000001053c0f1eb",
            "nonce": "28f42fb7",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299474,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "00000000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "558a4e86540cdced70d49d0ed9f97c2325e9029b"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "000000000db0bf0b",
            "nonce": "6b4b1a8a",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299476,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "000b0000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "5aa3fd58880be46f569e92e27aae8a622544cb55"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "000000000fe1bf0b",
            "nonce": "8399fe1c",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299476,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "00026000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "5aa3fd58880be46f569e92e27aae8a622544cb55"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "0000000012d9bf0b",
            "nonce": "e751bcc0",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299476,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "000c2000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "5aa3fd58880be46f569e92e27aae8a622544cb55"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "00000010e66df1eb",
            "nonce": "06831cfa",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299479,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "00000000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "02bfb1d9a87406ff99dfd9e5a7a4d41a291c86b2"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "000000111b1af1eb",
            "nonce": "b4bebdbe",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299484,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "17ffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "a528f3949fa0270244542c3abfa76cd6c189a774"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "0000000011d5bf0b",
            "nonce": "a03c92d6",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299491,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "000cc000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "4cbba70b9e0aab45c7c51045cff9e2340d57a307"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "00000011ee31f1eb",
            "nonce": "b7523123",
            "difficulty": 30516.134203009762,
            "bits": "1b0225c6",
            "timestamp": 1697299489,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "00000000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "29d5a93a6864c8c1c20dad368da39a336d118b3a"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "000000001823bf0b",
            "nonce": "b3c2de59",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299491,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "000ce000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "4cbba70b9e0aab45c7c51045cff9e2340d57a307"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "00000000052f0c28",
            "nonce": "14f662ce",
            "difficulty": 70500.68548916612,
            "bits": "1b00edf8",
            "timestamp": 1697299517,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "1fffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "8747959a43ad4c81f86ca540d303d9dc225dfcfb"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "000000001491bf0b",
            "nonce": "4f7565a4",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299501,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "00094000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "25925e369574ea8e4def11fb3bb2436a4316ad27"
        },
        {
            "extraNonce1": "0008",
            "extraNonce2": "00000000170abf0b",
            "nonce": "b77b0b1e",
            "difficulty": 329650.00586446957,
            "bits": "1a32e4ac",
            "timestamp": 1697299501,
            "miner": "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "node": "35SVLq9TUdsLBeK8FveAXSzVwvgoTFsoW6",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "00088000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "25925e369574ea8e4def11fb3bb2436a4316ad27"
        },
        {
            "extraNonce1": "0020",
            "extraNonce2": "00000000068a0c28",
            "nonce": "3a6b0e64",
            "difficulty": 70500.68548916612,
            "bits": "1b00edf8",
            "timestamp": 1697299524,
            "miner": "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "node": "3DR2xX6nDwfzUvRfw7cgSHZMnsPEfSXQma",
            "pplnsSequence": 304054,
            "pplnsChecksum": "9d3b",
            "indirectPayout": true,
            "versionBits": "1fffe000",
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "workId": "28b76397fe41291ab4de6675fe261b3e1847ba4b"
        }
    ],
    "ValidatorReferences": [
        {
            "tplHash": "938520a68222b5fbfdaf2c0736761b6ef5774303",
            "snapshotHash": "668a4c5c23ded3ca61ccfb196a6303b5eb82cef0"
        }
    ],
    "TotalWork": [
        [
            "3Jn4tJSAg34yBYcETppWxc7XkUaT4jjEV2",
            "941772"
        ],
        [
            "36n9ZHDS777qcY4C7xzytwPLc5J9Rjav4U",
            "3626150"
        ]
    ],
    "snapshotPhysicalSize": 2139,
    "snapshotAge": 274
}