import { FC } from "react";

export const SearchIcon: FC = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M29.6667 13C38.8714 13 46.3333 20.4619 46.3333 29.6667M48.5293 48.5163L63 63M56.3333 29.6667C56.3333 44.3943 44.3943 56.3333 29.6667 56.3333C14.9391 56.3333 3 44.3943 3 29.6667C3 14.9391 14.9391 3 29.6667 3C44.3943 3 56.3333 14.9391 56.3333 29.6667Z"
      stroke="#0A0A0A"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
