import { FC } from "react";
import styled from "styled-components";
import { LayoutProps } from "./types";

export const Container: FC<LayoutProps> = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  padding: 0 36px;
  margin: 0 auto;
  @media (min-width: 767px) {
    padding: 0 110px;
  }
  @media (min-width: 1440px) {
    padding: 0 104px;
  }
`;
