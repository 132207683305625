import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { configMenu } from "../../config";
import { colors } from "../../constants/colors";
import { Container } from "../layout";

export const Footer: FC = () => (
  <Container>
    <Inner>
        <Copy>© 2022&mdash;2023 Solomine.io. All Rights Reserved. Support:
            <Link to="mailto: support@solomine.io">support@solomine.io</Link></Copy>
      <Menu>
        {configMenu.map((item) => (
          <Link key={item.label} to={item.link}>
            <Item>{item.label}</Item>
          </Link>
        ))}
      </Menu>
    </Inner>
  </Container>
);

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 44px;
  padding-bottom: 36px;
  @media (min-width: 767px) {
    flex-direction: row;
    justify-content: space-between;
    margin-top: 53px;
  }
`;
const Copy = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: ${colors.gray};
`;
const Menu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
`;
const Item = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.dark};
`;
