export const colors = {
  bg: "#F4F4F4",
  alpha5: "rgba(255, 255, 255, 0.05)",
  alpha6: "rgba(254, 254, 254, 0.6)",
  blue: "#0070E4",
  blueLight: "#2B93FF",
  white: "#FFFFFF",
  dark: "#0A0A0A",
  dark2: "#131A29",
  shadow: "0px 0px 32px rgba(136, 152, 170, 0.15)",
  shadow2: "0px 1px 16px rgba(13, 20, 85, 0.12)",
  overlay: "rgba(78, 85, 91, 0.8)",
  gray: "#797979",
  light: "#FEFEFE",
  light2: '#F7F7F7',
};
