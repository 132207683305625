import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../../../../components/buttons";
import { Card } from "../../../../components/layout";
import { ROUTES } from "../../../../config";
import { colors } from "../../../../constants/colors";

export const JoinToPoolBlock: FC = () => (
  <Wrapper>
    <Card>
      <Inner>
        <Title>How to join to pool?</Title>
        <Description>Join any of the already available nodes.</Description>
        <Link to={ROUTES.JOIN}>
          <Button>Configure the Miner</Button>
        </Link>
      </Inner>
    </Card>
  </Wrapper>
);

const Wrapper = styled.div`
  margin-top: 40px;
  @media (min-width: 1440px) {
    margin-top: 16px;
  }
`;
const Inner = styled.div`
  padding: 48px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 1440px) {
    padding: 80px 60px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  text-align: center;
  color: ${colors.bg};
  @media (min-width: 1440px) {
    font-size: 48px;
  }
`;
const Description = styled.div`
  margin: 24px 32px;
  font-weight: 400;
  font-size: 16px;
  line-height: 188%;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${colors.light};
  opacity: 0.6;
  @media (min-width: 1440px) {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 40px;
  }
`;
