import { SearchIcon, DiamondIcon, EyeIcon } from "../../../../components/icons";

export const advantagesConfig = [
  {
    title: "Transparency",
    description:
      "A unique feature of our pool is the fixation in the blockchain for each miner of all the potential solutions he has found that can become a block - This ensures fair remuneration.",
    icon: <SearchIcon />,
  },
  {
    title: "Anonymous",
    description:
      "Solomine is an anonymous pool. Unlike centralized pools, our pool does not require registration. It is enough to create an anonymous key.",
    icon: <EyeIcon />,
  },
  {
    title: "Benefit",
    description:
      "The decentralized pool offers the lowest commission on the market at 2%.",
    icon: <DiamondIcon />,
  },
];
