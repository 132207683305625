import { FC } from "react";
import styled from "styled-components";
import { ELIPSE } from "../../constants/img";
import { LayoutProps } from "./types";

export const Card: FC<LayoutProps> = ({ children }) => (
  <Wrapper>
    <CardStyle>
      <Elispse src={ELIPSE} alt="" />
      {children}
    </CardStyle>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 0 8px;
  @media (min-width: 767px) {
    padding: 0 16px;
  }
`;
const CardStyle = styled.div`
  position: relative;
  background: linear-gradient(90deg, #4e555b 0%, #767d83 100%);
  border-radius: 24px;
  @media (min-width: 1440px) {
    border-radius: 36px;
  }
`;
const Elispse = styled.img`
  position: absolute;
  right: 0;
  top: 0;
`;
