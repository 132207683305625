import { FC } from "react";

export const EyeIcon: FC = () => (
  <svg
    width="70"
    height="54"
    viewBox="0 0 70 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <path
        d="M45 27C45 32.5229 40.5229 37 35 37C29.4772 37 25 32.5229 25 27C25 21.4772 29.4772 17 35 17C40.5229 17 45 21.4772 45 27Z"
        stroke="#0A0A0A"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0015 3.66669C20.076 3.66669 7.44176 13.4763 3.19418 26.9999C7.44169 40.5237 20.076 50.3334 35.0015 50.3334C49.927 50.3334 62.5612 40.5238 66.8088 27.0001C62.5613 13.4764 49.927 3.66669 35.0015 3.66669Z"
        stroke="#0A0A0A"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
