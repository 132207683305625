import { FC } from "react";
import styled from "styled-components";
import { TitleBlock } from "../../components/titleBlock";
import { colors } from "../../constants/colors";
import { CODE_BLUE } from "../../constants/img";
import useResize from "../../hooks/useResize";
import { joinConfig } from "./config";
import { Helmet } from "react-helmet-async";

export const Join: FC = () => {
  const { width } = useResize();
  return (
    <>
      <Helmet>
        <title>SOLOMINE - Join</title>
      </Helmet>
      <TitleBlock
        title="Join to Pool"
        description="How to connect to Solomine?"
      />
      <Wrapper>
        <Block>
          {joinConfig.map((item, i) => (
            <Card key={i}>
              <CardInner>
                <Number>{i+1}.</Number>
                <div>
                  <Text>{item.text}</Text>
                  <Description lineWidth={width}>
                    {item.description}
                  </Description>
                </div>
              </CardInner>
              <Img src={CODE_BLUE} alt="" />
            </Card>
          ))}
        </Block>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  margin-top: 16px;
  padding: 0 8px;
  @media (min-width: 767px) {
    padding: 0 16px;
  }
`;
const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 40px 12px;
  background: ${colors.light2};
  border-radius: 16px;
  @media (min-width: 767px) {
    border-radius: 32px;
  }
  @media (min-width: 1440px) {
    padding: 72px 88px;
  }
`;
const Card = styled.div`
  position: relative;
  padding: 24px 16px;
  height: 100%;
  width: 100%;
  box-shadow: ${colors.shadow2};
  border-radius: 16px;
  background: ${colors.light};
  overflow: hidden;
  @media (min-width: 767px) {
    padding: 32px;
  }
`;
const Img = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  @media (min-width: 767px) {
    display: block;
  }
`;
const CardInner = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  @media (min-width: 767px) {
    gap: 16px;
  }
`;
const Number = styled.div`
  width: 30px;
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  color: ${colors.blue};
  @media (min-width: 767px) {
    font-size: 24px;
    line-height: 24px;
  }
`;
const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: ${colors.dark};
  opacity: 0.8;
  @media (min-width: 767px) {
    font-size: 16px;
    line-height: 28px;
  }
`;
const Description = styled(Text)<{ lineWidth: number | null }>`
  font-weight: 700;
  inline-size: ${({ lineWidth }) =>
    lineWidth ? `${lineWidth - 100}px` : "100%"};
  overflow-wrap: break-word;
  @media (min-width: 767px) {
    inline-size: 100%;
  }
`;
