import { FC } from "react";
import styled from "styled-components";
import { TitleBlock } from "../../components/titleBlock";
import { colors } from "../../constants/colors";
import { DescriptionBlock } from "./components/DescriptionBlock";
import { Helmet } from "react-helmet-async";
export const About: FC = () => (
  <>
    <Helmet>
      <title>SOLOMINE - About</title>
    </Helmet>
    <div>
      <TitleBlock title="About" description="Information about us" />
      <Title>
        Optimize your computing power with a revolutionary BTC block discovery
        stake allocation system.
      </Title>
      <DescriptionBlock />
    </div>
  </>
);

const Title = styled.div`
  margin: 56px 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: ${colors.dark};
  @media (min-width: 767px) {
    margin: 56px 40px;
  }
`;
