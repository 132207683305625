import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../constants/colors";
import { ellipsis } from "../../../../utils/ellipsis";
import moment from "moment";
import SEED from "../../../../config/snapshot";

const head = ["Time block", "Miner", "Difficulty"];
const defualtTable = [...SEED.Shares];

export const Table: FC<{
  list: any;
}> = ({ list = [] }) => {
  const [data, setData] = useState(defualtTable);
  const shares =
    data.map((share: any) => ({
      timestamp: share.timestamp,
      miner: share.miner,
      difficulty: share.difficulty,
    }));

  useEffect(() => {
    if (list.length > 0) {
      setData(list);
    } else {
      setData(defualtTable);
    }
  }, [list]);
  return (
    <>
      <HeadRow>
        {head.map((item, i) => (
          <HeadCell
            style={{ textAlign: i + 1 === head.length ? "right" : "left" }}
            key={item}
          >
            {item}
          </HeadCell>
        ))}
      </HeadRow>
      <Wrapper>
        <TableWrap>
          {shares.map((item: any, i: number) => (
            <Row key={i}>
              <Cell>{moment.unix(item.timestamp).format("DD.MM.YYYY")}</Cell>
              <Cell>{ellipsis(item.miner, 5)}</Cell>
              <Cell style={{ textAlign: "right" }}>
                {`${item.difficulty.toFixed(1)} M`}
              </Cell>
            </Row>
          ))}
        </TableWrap>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  overflow: auto;
  height: 237px;
  padding-right: 20px;
`;
const TableWrap = styled.div`
  overflow-x: auto;
  position: relative;
`;

const Row = styled.div`
  min-width: 500px;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  padding: 12px 0;
  border-bottom: 1px solid ${colors.gray};
  &:last-child {
    border-bottom: none;
  }
`;
const HeadCell = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 130%;
  color: ${colors.gray};
  @media (min-width: 767px) {
    font-size: 14px;
  }
`;
const Cell = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  color: ${colors.dark};
`;
const HeadRow = styled(Row)`
  padding-right: 20px;
`;
