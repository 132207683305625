import { FC, useState } from "react";
import Collapsible from "react-collapsible";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Card } from "../../../../components/layout";
import { ROUTES } from "../../../../config";
import { colors } from "../../../../constants/colors";
import { ARROW } from "../../../../constants/img";
import { faqConfig } from "../../config";

export const FaqBlock: FC = () => {
  const [current, setCurrent] = useState<number | null>(null);
  return (
    <Wrapper>
      <Card>
        <Inner>
          <Link to={ROUTES.FAQ}>
            <Title>FAQ</Title>
          </Link>
          <Description>
            Here you can find answers to popular questions
          </Description>
          <Content>
            {faqConfig.map((faq, i) => (
              <Collapsible
                onOpening={() => setCurrent(i)}
                onClosing={() => setCurrent(null)}
                key={i}
                trigger={
                  <Head>
                    {faq.title}{" "}
                    <Arrow open={current === i} src={ARROW} alt="" />
                  </Head>
                }
              >
                <Text>{faq.description}</Text>
              </Collapsible>
            ))}
          </Content>
        </Inner>
      </Card>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 40px;
  @media (min-width: 767px) {
    margin-top: 16px;
  }
`;
const Inner = styled.div`
  padding: 40px 16px;
  @media (min-width: 767px) {
    padding: 48px 24px;
  }
  @media (min-width: 1440px) {
    padding: 72px 88px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  color: ${colors.light};
  @media (min-width: 767px) {
    font-size: 34px;
  }
  @media (min-width: 1440px) {
    font-size: 44px;
    line-height: 44px;
  }
`;
const Description = styled.div`
  margin: 8px 0 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 188%;
  text-align: left;
  letter-spacing: -0.02em;
  color: ${colors.light};
  opacity: 0.6;
  @media (min-width: 767px) {
    font-size: 18px;
    line-height: 30px;
    margin: 16px 0 32px;
  }
  @media (min-width: 1440px) {
    margin: 16px 0 48px;
  }
`;
const Content = styled.div`
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr;
  margin-top: 24px;
  @media (min-width: 767px) {
    margin-top: 32px;
  }
  @media (min-width: 1440px) {
    margin-top: 48px;
    grid-template-columns: 1fr 1fr;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  background: ${colors.white};
  border-radius: 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${colors.dark};
  cursor: pointer;
  @media (min-width: 767px) {
    font-size: 16px;
  }
`;
const Text = styled(Head)`
  margin-top: 12px;
  background: ${colors.white};
  cursor: default;
  font-weight: 400;
`;
const Arrow = styled.img<{ open: boolean }>`
  display: block;
  position: relative;
  transition: all 0.3s;
  transform: ${({ open }) => (open ? "rotateX(180deg)" : "")};
`;
