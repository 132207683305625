import React from "react";
import { Main } from "./pages/main";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { About } from "./pages/about";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { FAQ } from "./pages/faq";
import { ROUTES } from "./config";
import { Join } from "./pages/join";
import { HelmetProvider } from "react-helmet-async";
import './styles/App.css'

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route path={ROUTES.HOME} exact>
            <Main />
          </Route>
          <Route path={ROUTES.ABOUT} exact>
            <About />
          </Route>
          <Route path={ROUTES.FAQ} exact>
            <FAQ />
          </Route>
          <Route path={ROUTES.JOIN} exact>
            <Join />
          </Route>
        </Switch>
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
