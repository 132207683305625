import { FC } from "react";
import styled from "styled-components";
import { Card } from "../../../../components/layout";
import { TitleWhite } from "../../../../components/text";
import { colors } from "../../../../constants/colors";
import { advantagesConfig } from "./config";

export const Advantages: FC = () => (
  <Wrapper>
    <Card>
      <Inner>
        <TitleWhite>Our advantages </TitleWhite>
        <Items>
          {advantagesConfig.map((item) => (
            <Item key={item.title}>
              <Title>{item.title}</Title>
              <Description>{item.description}</Description>
              <Icon>{item.icon}</Icon>
            </Item>
          ))}
        </Items>
      </Inner>
    </Card>
  </Wrapper>
);

const Wrapper = styled.div`
  margin-top: 56px;
`;
const Inner = styled.div`
  padding: 40px 12px;
  @media (min-width: 767px) {
    padding: 48px 24px;
  }
  @media (min-width: 1440px) {
    padding: 72px 88px;
  }
`;
const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 767px) {
    padding: 32px 24px;
    flex-direction: row;
    flex-wrap: wrap;
  }
  @media (min-width: 1440px) {
    gap: 24px;
    flex-wrap: nowrap;
  }
`;
const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  margin-top: 12px;
  @media (min-width: 1440px) {
    margin-top: 20px;
  }
`;
const Item = styled.div`
  position: relative;
  padding: 24px 16px;
  border-radius: 16px;
  background: ${colors.light};
  &:not(last-child) {
    & ${Description} {
      width: 70%;
    }
  }
  @media (min-width: 767px) {
    padding: 32px 24px;
    flex-wrap: wrap;
    &:first-child {
      & ${Description} {
        width: 70%;
      }
    }
    &:last-child {
      width: 100%;
    }
    &:not(last-child) {
      width: 50%;
      width: calc(50% - 8px);
    }
  }
  @media (min-width: 1440px) {
    width: 100% !important;
    min-height: 302px;
    & ${Description} {
      width: 100% !important;
    }
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  @media (min-width: 1440px) {
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
  }
`;
const Icon = styled.div`
  position: absolute;
  right: 25px;
  bottom: 25px;
  & svg {
    width: 33px;
    height: 33px;
  }
  @media (min-width: 1440px) {
    right: 34px;
    bottom: 34px;
    & svg {
      width: 60px;
      height: 60px;
    }
  }
`;
