import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";
import { ELIPSE_2 } from "../../constants/img";

type TitleBlockProps = {
  title: string;
  description: string;
};

export const TitleBlock: FC<TitleBlockProps> = ({ title, description }) => (
  <Wrapper>
    <Block>
      <Elispse src={ELIPSE_2} alt="" />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Block>
  </Wrapper>
);

const Wrapper = styled.div`
  padding: 0 8px;
  @media (min-width: 767px) {
    padding: 0 16px;
  }
`;

const Elispse = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  @media (min-width: 767px) {
    display: block;
  }
`;
const Block = styled.div`
  padding: 104px 28px 48px;
  position: relative;
  background: linear-gradient(90deg, #4e555b 0%, #767d83 100%);
  border-radius: 0px 0px 24px 24px;
  text-align: center;
  @media (min-width: 767px) {
    text-align: left;
    border-radius: 0px 0px 32px 32px;
    padding: 152px 96px 88px;
  }
  @media (min-width: 1440px) {
    padding: 192px 88px 112px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 32px;
  color: ${colors.light};
  @media (min-width: 767px) {
    font-size: 40px;
    line-height: 40px;
  }
  @media (min-width: 1440px) {
    font-size: 44px;
    line-height: 44px;
  }
`;
const Description = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: ${colors.alpha6};
  @media (min-width: 767px) {
    margin-top: 24px;
  }
  @media (min-width: 1440px) {
    margin-top: 16px;
    font-size: 18px;
    line-height: 26px;
  }
`;
