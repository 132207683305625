import { ApexOptions } from "apexcharts";
import { FC, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
import { CardWhite } from "../../../../components/layout/CardWhite";
import { GreyCard } from "../greyCard";
import { Table } from "./Table";
import CHART_DATA from "../../../../config/gourlyChart";
import moment from "moment";
import { Shares } from "./types";

const series1: ApexOptions["series"] = [
  {
    name: "hourly",
    data: CHART_DATA.values
      .reverse()
      .splice(0, 10)
      .reverse()
      .map((slice) => ({
        x: moment.unix(slice.x).format("DD.MM"),
        y: `${(slice.y / 1200000000000).toFixed(2)} T`,
      })),
  },
];

type NodesProps = {
  shares: Shares[];
  hourlyChart: any;
};

export const Nodes: FC<NodesProps> = ({ shares, hourlyChart }) => {
  const [series, setSeries] = useState(series1);
  
  useEffect(() => {
    if (hourlyChart?.length > 0) {
      const series2: ApexOptions["series"] = [
        {
          name: "hourly",
          data: hourlyChart
            .reverse()
            .splice(0, 10)
            .map((slice: any) => ({
              x: moment.unix(slice.sliceTime).format("HH:mm"),
              y: (+slice.hashRate / 1200000000000).toFixed(0),
            })),
        },
      ];
      setSeries(series2);
    } else {
      setSeries(series1);
    }
  }, [hourlyChart]);

  const options: ApexOptions = {
    chart: {
      toolbar: { show: false },
      fontFamily: "Inter",
      group: "social",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.5,
        opacityFrom: 0.6,
        opacityTo: 0.2,
        stops: [0, 50, 100],
        gradientToColors: ["#0070E4", "#cce2fa"],
      },
    },
    colors: ["#0070E4"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
      colors: ["#0070E4"],
    },
    markers: {
      size: 4,
      strokeWidth: 0,
      hover: {
        size: 6,
      },
    },
    yaxis: {
      crosshairs: {
        show: true,
        stroke: { color: "transparent" },
      },
      tooltip: {
        enabled: false,
      },
      opposite: false,
      axisBorder: {
        color: "rgba(0, 0, 0, 0.05)",
        show: false,
      },
      labels: {
        align: "left",
        offsetX: 0,
        style: {
          colors: "#797979",
          fontSize: "12px",
        },
      },
    },
    xaxis: {
      type: "category",
      // tickPlacement: "on",
      axisTicks: {
        show: false,
      },
      axisBorder: {
        color: "rgba(0, 0, 0, 0.05)",
        show: false,
      },
      labels: {
        datetimeFormatter: {
          day: "mm ss",
        },
        style: {
          colors: "#797979",
          fontSize: "12px",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      custom: function ({
        series: seriesChart,
        seriesIndex,
        dataPointIndex,
        w,
      }) {
        return null;
      },
    },
    legend: {
      show: false,
    },
    grid: {
      borderColor: "rgba(30, 16, 16, 0.05)",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
  return (
    <CardWhite title="Nodes/pools ">
      <Inner>
        <GreyCard title="Pool speed">
          <Table list={shares} />
        </GreyCard>
        <GreyCard title="Network Difficulty">
          <ChartWrapper id="nodes">
            <Chart options={options} series={series} type="area" height={300} />
          </ChartWrapper>
        </GreyCard>
      </Inner>
    </CardWhite>
  );
};

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  @media (min-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ChartWrapper = styled.div`
  position: relative;
`;
