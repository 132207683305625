import { FC } from "react";
import styled from "styled-components";
import { Title } from "../../../../components/text";

export const AboutBlock: FC = () => (
  <Inner>
    <Title>About us</Title>
    <Description>
      This is the world's first and only working solution on the market that
      supports the joint operation of decentralized mining pools in Solo mode.
    </Description>
  </Inner>
);

const Inner = styled.div`
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 20px;
  @media (min-width: 767px) {
    padding: 0 40px;
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: 1440px) {
    padding: 0 120px;
  }
`;
const Description = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  @media (min-width: 767px) {
    font-size: 18px;
    line-height: 26px;
    width: 50%;
  }
`;
