import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";

type TextProps = { children: string };

export const Title: FC<TextProps> = ({ children }) => (
  <TitleStyle>{children}</TitleStyle>
);

export const TitleWhite: FC<TextProps> = ({ children }) => (
  <TitleWhiteStyle>{children}</TitleWhiteStyle>
);

const TitleStyle = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  color: ${colors.dark};
  @media (min-width: 767px) {
    font-size: 28px;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
    line-height: 44px;
  }
`;

const TitleWhiteStyle = styled.div`
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  color: ${colors.white};
  @media (min-width: 767px) {
    font-size: 34px;
  }
  @media (min-width: 1440px) {
    font-size: 44px;
    line-height: 44px;
  }
`;
