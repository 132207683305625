import { FC, ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../../../../constants/colors";

export const GreyCard: FC<{ children: ReactNode; title?: string }> = ({
  title,
  children,
}) => (
  <Warpper>
    {title && <Title>{title}</Title>}
    {children}
  </Warpper>
);

const Warpper = styled.div`
  padding: 16px 8px;
  background: rgba(10, 10, 10, 0.03);
  border-radius: 12px;
  padding: 24px;
  @media (min-width: 767px) {
    padding: 24px;
  }
  overflow-x: auto;
`;

const Title = styled.div`
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.dark};
`;
