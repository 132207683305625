export const faqConfig = [
  {
    title: "What is a block?",
    description:
      "This is a fragment of the Bitcoin chain that meets the specific requirements of the network. Successfully finding a block means that the pool receives a reward, which it distributes among users.",
  },
  {
    title: "How are the charges going?",
    description:
      "In the accrual system we use, we pay in real time for each decision received from users, this approach is called Pay-Per-Share (PPS).",
  },
  {
    title:
      "Will your code be open to the community like all decentralized projects?",
    description: "Yes",
  },
  {
    title:
      "What is the principle of the p2p network you implemented, what is its architecture?",
    description:
      "A normal peer-to-peer network with direct data exchange between participants.",
  },
];
