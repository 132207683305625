import { FC } from "react";

export const DiamondIcon: FC = () => (
  <svg
    width="66"
    height="66"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.2"
      d="M26.3334 4L19.6667 24L33 62.3333L46.3334 24L39.6667 4M4.66669 24H61.3334M35.1334 61.1556L61.8 25.6C62.2303 25.0262 62.4455 24.7394 62.5284 24.4243C62.6017 24.1462 62.6017 23.8538 62.5284 23.5757C62.4455 23.2606 62.2303 22.9738 61.8 22.4L48.8 5.06667C48.5067 4.67556 48.36 4.48 48.1741 4.33896C48.0095 4.21404 47.8231 4.12083 47.6244 4.06408C47.4 4 47.1556 4 46.6667 4H19.3334C18.8445 4 18.6 4 18.3757 4.06408C18.177 4.12083 17.9905 4.21404 17.8259 4.33896C17.64 4.48 17.4934 4.67556 17.2 5.06667L4.20002 22.4C3.7697 22.9738 3.55454 23.2606 3.4716 23.5757C3.39838 23.8538 3.39838 24.1462 3.4716 24.4243C3.55454 24.7393 3.7697 25.0262 4.20002 25.6L30.8667 61.1556C31.5904 62.1205 31.9523 62.603 32.3959 62.7755C32.7845 62.9267 33.2156 62.9267 33.6041 62.7755C34.0478 62.603 34.4096 62.1205 35.1334 61.1556Z"
      stroke="#0A0A0A"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
