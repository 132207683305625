// http://80.249.131.181:8888/general
export default {
    "uptime": 145304,
    "name": "russian:Node",
    "ea": {
        "topSnapshotHash": "4c408cf97ee96ffa4847a921bbc835658ea81fa5",
        "topSnapshotSequence": 304059,
        "topSnapshotAge": 31,
        "snapshotDifficulty": 3051600.8852598243,
        "expectedSecondsToSnapshot": 30,
        "globalHashRateShort": "425880659921127",
        "globalHashRateLong": "400964009315446"
    },
    "bitcoin": {
        "networkHashRateShort": "620136028216770731351",
        "networkHashRateLong": "420590124823672215311",
        "topBlockHeight": 812165,
        "blockDifficulty": 57321508229258.04,
        "expectedSecondsToBlock": 578090960
    }
}