import { FC } from "react";
import styled from "styled-components";
import { colors } from "../../../constants/colors";
import { CODE_BLUE } from "../../../constants/img";
import { aboutTexts } from "../config";

export const DescriptionBlock: FC = () => (
  <Warapper>
    <Block>
      <Card>
        <Title>Solomine Mission</Title>
        <Description>
          Solomine's mission is to make your own mining income more
          controllable.
        </Description>
        <Img src={CODE_BLUE} alt="" />
      </Card>
    </Block>
    <Block>
      <Grid>
        {aboutTexts.map((text, i) => (
          <CardWrapper key={i}>
            <Card key={i}>
              <Text>{text}</Text>
              {i === 2 && <Img src={CODE_BLUE} alt="" />}
            </Card>
          </CardWrapper>
        ))}
      </Grid>
    </Block>
  </Warapper>
);

const Warapper = styled.div`
  margin-top: 56px;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 767px) {
    padding: 0 16px;
  }
`;
const Block = styled.div`
  padding: 40px 12px;
  background: ${colors.light2};
  border-radius: 16px;
  @media (min-width: 767px) {
    padding: 72px 24px;
    border-radius: 32px;
  }
  @media (min-width: 1440px) {
    padding: 72px 88px;
  }
`;
const Card = styled.div`
  position: relative;
  padding: 32px;
  height: 100%;
  width: 100%;
  box-shadow: ${colors.shadow2};
  border-radius: 16px;
  background: ${colors.light};
  overflow: hidden;
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 26px;
  line-height: 123%;
  color: ${colors.blue};
  @media (min-width: 767px) {
    font-size: 34px;
    line-height: 44px;
  }
  @media (min-width: 1440px) {
    font-size: 44px;
    line-height: 44px;
  }
`;
const Description = styled.div`
  margin-top: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 178%;
  color: ${colors.dark};
  opacity: 0.8;
  @media (min-width: 767px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }
  @media (min-width: 1440px) {
    font-size: 16px;
  }
`;
const Grid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  display: grid;
  grid-template-columns: 1fr;
  @media (min-width: 1440px) {
    flex-direction: row;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);
  }
`;
const Text = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${colors.dark};
  opacity: 0.8;
`;
const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  @media (min-width: 1440px) {
    &:last-child {
      grid-column: 1/3;
    }
  }
`;
const Img = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  display: none;
  @media (min-width: 1440px) {
    display: block;
  }
`;
