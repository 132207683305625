import { FC } from "react";

export const LogoIcon: FC = () => (
  <svg
    width="111"
    height="22"
    viewBox="0 0 111 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.3735 3.49517C33.3277 3.03821 33.131 2.68322 32.7835 2.43019C32.436 2.17717 31.9644 2.05065 31.3687 2.05065C30.9639 2.05065 30.6221 2.1073 30.3434 2.2206C30.0646 2.33012 29.8508 2.48306 29.7018 2.67944C29.5567 2.87582 29.4842 3.09864 29.4842 3.34789C29.4765 3.5556 29.5204 3.73687 29.6159 3.89171C29.7152 4.04654 29.8508 4.18061 30.0226 4.29391C30.1944 4.40343 30.393 4.49973 30.6183 4.58281C30.8436 4.66212 31.0842 4.7301 31.34 4.78674L32.394 5.03599C32.9057 5.14929 33.3754 5.30035 33.8031 5.48918C34.2308 5.678 34.6012 5.91026 34.9143 6.18595C35.2275 6.46163 35.47 6.78641 35.6418 7.16029C35.8175 7.53416 35.9072 7.9628 35.911 8.44619C35.9072 9.15618 35.7239 9.77175 35.3611 10.2929C35.0022 10.8103 34.4828 11.2125 33.8031 11.4995C33.1272 11.7828 32.3119 11.9244 31.3572 11.9244C30.4102 11.9244 29.5854 11.7809 28.8827 11.4938C28.1839 11.2068 27.6378 10.782 27.2445 10.2193C26.855 9.65279 26.6507 8.95225 26.6316 8.11764H29.0316C29.0584 8.50662 29.171 8.8314 29.3696 9.09198C29.572 9.34878 29.8412 9.54328 30.1773 9.67545C30.5171 9.80386 30.9009 9.86806 31.3286 9.86806C31.7486 9.86806 32.1133 9.80763 32.4226 9.68678C32.7358 9.56593 32.9783 9.39788 33.1501 9.18262C33.3219 8.96735 33.4079 8.71999 33.4079 8.44053C33.4079 8.17995 33.3296 7.96091 33.173 7.78341C33.0203 7.60592 32.795 7.45486 32.4971 7.33023C32.2031 7.20561 31.8422 7.09231 31.4145 6.99034L30.1372 6.67312C29.1481 6.4352 28.3672 6.06321 27.7944 5.55715C27.2216 5.0511 26.9371 4.36944 26.9409 3.51217C26.9371 2.80973 27.1261 2.19605 27.508 1.67111C27.8937 1.14618 28.4226 0.736422 29.0947 0.441853C29.7667 0.147284 30.5305 0 31.3859 0C32.2565 0 33.0165 0.147284 33.6656 0.441853C34.3186 0.736422 34.8265 1.14618 35.1893 1.67111C35.5521 2.19605 35.7392 2.80407 35.7506 3.49517H33.3735Z"
      fill="white"
    />
    <path
      d="M48.3595 5.95935C48.3595 7.22449 48.117 8.3008 47.632 9.18828C47.1509 10.0758 46.4941 10.7536 45.6616 11.2219C44.8329 11.6865 43.9012 11.9187 42.8663 11.9187C41.8238 11.9187 40.8882 11.6846 40.0596 11.2163C39.2309 10.748 38.576 10.0701 38.0948 9.18262C37.6137 8.29513 37.3731 7.22071 37.3731 5.95935C37.3731 4.69422 37.6137 3.61791 38.0948 2.73043C38.576 1.84294 39.2309 1.16695 40.0596 0.702434C40.8882 0.234145 41.8238 0 42.8663 0C43.9012 0 44.8329 0.234145 45.6616 0.702434C46.4941 1.16695 47.1509 1.84294 47.632 2.73043C48.117 3.61791 48.3595 4.69422 48.3595 5.95935ZM45.8449 5.95935C45.8449 5.13985 45.7208 4.44874 45.4726 3.88604C45.2282 3.32334 44.8826 2.89659 44.4358 2.6058C43.989 2.31501 43.4658 2.16961 42.8663 2.16961C42.2668 2.16961 41.7436 2.31501 41.2968 2.6058C40.85 2.89659 40.5025 3.32334 40.2543 3.88604C40.0099 4.44874 39.8877 5.13985 39.8877 5.95935C39.8877 6.77886 40.0099 7.46996 40.2543 8.03266C40.5025 8.59537 40.85 9.02211 41.2968 9.31291C41.7436 9.6037 42.2668 9.7491 42.8663 9.7491C43.4658 9.7491 43.989 9.6037 44.4358 9.31291C44.8826 9.02211 45.2282 8.59537 45.4726 8.03266C45.7208 7.46996 45.8449 6.77886 45.8449 5.95935Z"
      fill="white"
    />
    <path
      d="M50.1968 11.7601V0.158614H52.677V9.73777H57.7062V11.7601H50.1968Z"
      fill="white"
    />
    <path
      d="M69.6721 5.95935C69.6721 7.22449 69.4296 8.3008 68.9447 9.18828C68.4635 10.0758 67.8067 10.7536 66.9742 11.2219C66.1456 11.6865 65.2138 11.9187 64.1789 11.9187C63.1364 11.9187 62.2008 11.6846 61.3722 11.2163C60.5435 10.748 59.8886 10.0701 59.4075 9.18262C58.9263 8.29513 58.6857 7.22071 58.6857 5.95935C58.6857 4.69422 58.9263 3.61791 59.4075 2.73043C59.8886 1.84294 60.5435 1.16695 61.3722 0.702434C62.2008 0.234145 63.1364 0 64.1789 0C65.2138 0 66.1456 0.234145 66.9742 0.702434C67.8067 1.16695 68.4635 1.84294 68.9447 2.73043C69.4296 3.61791 69.6721 4.69422 69.6721 5.95935ZM67.1575 5.95935C67.1575 5.13985 67.0334 4.44874 66.7852 3.88604C66.5408 3.32334 66.1952 2.89659 65.7484 2.6058C65.3016 2.31501 64.7785 2.16961 64.1789 2.16961C63.5794 2.16961 63.0562 2.31501 62.6094 2.6058C62.1627 2.89659 61.8152 3.32334 61.5669 3.88604C61.3225 4.44874 61.2003 5.13985 61.2003 5.95935C61.2003 6.77886 61.3225 7.46996 61.5669 8.03266C61.8152 8.59537 62.1627 9.02211 62.6094 9.31291C63.0562 9.6037 63.5794 9.7491 64.1789 9.7491C64.7785 9.7491 65.3016 9.6037 65.7484 9.31291C66.1952 9.02211 66.5408 8.59537 66.7852 8.03266C67.0334 7.46996 67.1575 6.77886 67.1575 5.95935Z"
      fill="white"
    />
    <path
      d="M71.5094 0.158614H74.5682L77.7988 7.95336H77.9363L81.1669 0.158614H84.2256V11.7601H81.8199V4.20894H81.7225L78.6866 11.7034H77.0484L74.0125 4.18061H73.9152V11.7601H71.5094V0.158614Z"
      fill="white"
    />
    <path
      d="M88.7494 0.158614V11.7601H86.2691V0.158614H88.7494Z"
      fill="white"
    />
    <path
      d="M100.602 0.158614V11.7601H98.4598L93.3562 4.45819H93.2702V11.7601H90.79V0.158614H92.9666L98.0302 7.45486H98.1333V0.158614H100.602Z"
      fill="white"
    />
    <path
      d="M102.651 11.7601V0.158614H110.556V2.18094H105.132V4.94536H110.149V6.96769H105.132V9.73777H110.579V11.7601H102.651Z"
      fill="white"
    />
    <path
      d="M27.7838 21.9079V16.5215H30.9352V16.9634H28.2811V18.9912H30.6879V19.433H28.2811V21.9079H27.7838Z"
      fill="white"
    />
    <path
      d="M32.0788 21.9079V17.8681H32.5548V21.9079H32.0788ZM32.3208 17.1738C32.2233 17.1738 32.1399 17.1414 32.0708 17.0765C32.0016 17.0098 31.9671 16.9301 31.9671 16.8371C31.9671 16.7442 32.0016 16.6653 32.0708 16.6004C32.1399 16.5338 32.2233 16.5005 32.3208 16.5005C32.4183 16.5005 32.5016 16.5338 32.5708 16.6004C32.6399 16.6653 32.6745 16.7442 32.6745 16.8371C32.6745 16.9301 32.6399 17.0098 32.5708 17.0765C32.5016 17.1414 32.4183 17.1738 32.3208 17.1738Z"
      fill="white"
    />
    <path
      d="M33.8067 21.9079V17.8681H34.2668V18.4888H34.3014C34.383 18.2854 34.5248 18.1215 34.7269 17.997C34.9308 17.8708 35.1613 17.8076 35.4184 17.8076C35.4574 17.8076 35.5008 17.8085 35.5487 17.8103C35.5966 17.812 35.6364 17.8138 35.6684 17.8155V18.2916C35.6471 18.2881 35.6098 18.2828 35.5567 18.2758C35.5035 18.2688 35.4458 18.2653 35.3838 18.2653C35.171 18.2653 34.9813 18.31 34.8147 18.3994C34.6498 18.4871 34.5195 18.6089 34.4237 18.765C34.328 18.921 34.2801 19.099 34.2801 19.2989V21.9079H33.8067Z"
      fill="white"
    />
    <path
      d="M39.2207 18.7545L38.7872 18.8754C38.7447 18.7545 38.6844 18.6449 38.6064 18.5467C38.5284 18.4485 38.4264 18.3705 38.3005 18.3126C38.1764 18.2548 38.0222 18.2258 37.8378 18.2258C37.5612 18.2258 37.3343 18.2907 37.157 18.4204C36.9797 18.5502 36.891 18.7176 36.891 18.9228C36.891 19.0964 36.9513 19.2375 37.0719 19.3462C37.1942 19.4532 37.3821 19.5382 37.6357 19.6014L38.2527 19.7513C38.5949 19.8337 38.8511 19.9643 39.0213 20.1431C39.1932 20.322 39.2792 20.5464 39.2792 20.8164C39.2792 21.0444 39.2154 21.2469 39.0877 21.424C38.9601 21.6011 38.7819 21.7405 38.5532 21.8422C38.3263 21.9421 38.063 21.9921 37.7633 21.9921C37.3644 21.9921 37.0355 21.9035 36.7767 21.7265C36.5178 21.5476 36.3521 21.2899 36.2794 20.9532L36.7341 20.8427C36.7926 21.083 36.907 21.2644 37.0772 21.3872C37.2492 21.5099 37.4752 21.5713 37.7554 21.5713C38.0692 21.5713 38.32 21.502 38.508 21.3635C38.6959 21.2232 38.7899 21.0479 38.7899 20.8375C38.7899 20.6744 38.7349 20.5377 38.625 20.4272C38.5151 20.315 38.3484 20.2326 38.125 20.18L37.4575 20.0222C37.1029 19.938 36.8405 19.8047 36.6703 19.6224C36.5001 19.44 36.415 19.2139 36.415 18.9438C36.415 18.7212 36.4762 18.5256 36.5985 18.3573C36.7208 18.1872 36.8893 18.054 37.1038 17.9576C37.3183 17.8611 37.563 17.8129 37.8378 17.8129C38.2119 17.8129 38.5106 17.8971 38.734 18.0654C38.9592 18.232 39.1214 18.4617 39.2207 18.7545Z"
      fill="white"
    />
    <path
      d="M41.9247 17.8681V18.2758H39.9753V17.8681H41.9247ZM40.5843 16.9003H41.0604V20.8533C41.0604 21.0216 41.0896 21.154 41.1482 21.2504C41.2067 21.3451 41.2829 21.4126 41.3769 21.4529C41.4708 21.4915 41.571 21.5108 41.6774 21.5108C41.7394 21.5108 41.7926 21.5073 41.8369 21.5003C41.8813 21.4915 41.9203 21.4827 41.954 21.474L42.055 21.8974C42.0089 21.9149 41.9522 21.9307 41.8848 21.9448C41.8174 21.9605 41.7341 21.9684 41.6348 21.9684C41.4611 21.9684 41.2935 21.9307 41.1322 21.8553C40.9726 21.7799 40.8414 21.6677 40.7386 21.5187C40.6358 21.3696 40.5843 21.1847 40.5843 20.9637V16.9003Z"
      fill="white"
    />
    <path
      d="M46.8001 21.9079H45.2045V16.5215H46.8959C47.4154 16.5215 47.8595 16.6285 48.2283 16.8424C48.5988 17.0563 48.8825 17.3632 49.0793 17.7629C49.2761 18.1627 49.3745 18.6422 49.3745 19.2016C49.3745 19.7662 49.2734 20.251 49.0713 20.656C48.871 21.0593 48.5784 21.3688 48.1937 21.5844C47.8107 21.8001 47.3462 21.9079 46.8001 21.9079ZM45.7018 21.4661H46.7709C47.239 21.4661 47.6308 21.3749 47.9464 21.1925C48.262 21.0102 48.4986 20.7498 48.6564 20.4114C48.8142 20.073 48.8931 19.6697 48.8931 19.2016C48.8914 18.7369 48.8133 18.3372 48.6591 18.0023C48.5066 17.6674 48.2797 17.4105 47.9783 17.2317C47.6787 17.0528 47.3081 16.9634 46.8666 16.9634H45.7018V21.4661Z"
      fill="white"
    />
    <path
      d="M52.133 21.9921C51.7518 21.9921 51.422 21.9044 51.1437 21.7291C50.8653 21.552 50.6499 21.3074 50.4974 20.9953C50.3467 20.6814 50.2714 20.3202 50.2714 19.9117C50.2714 19.5049 50.3467 19.1437 50.4974 18.8281C50.6499 18.5107 50.86 18.2626 51.1277 18.0838C51.3972 17.9032 51.7084 17.8129 52.0612 17.8129C52.2828 17.8129 52.4965 17.8532 52.7021 17.9339C52.9078 18.0128 53.0922 18.1346 53.2553 18.2995C53.4202 18.4625 53.5505 18.6685 53.6462 18.9175C53.742 19.1648 53.7898 19.4576 53.7898 19.796V20.0274H50.5985V19.6145H53.3058C53.3058 19.355 53.2526 19.1218 53.1463 18.9149C53.0417 18.7062 52.8954 18.5414 52.7075 18.4204C52.5213 18.2995 52.3059 18.239 52.0612 18.239C51.8023 18.239 51.5745 18.3074 51.3777 18.4441C51.1809 18.5809 51.0267 18.7615 50.915 18.9859C50.8051 19.2103 50.7492 19.4558 50.7474 19.7223V19.9696C50.7474 20.2904 50.8033 20.571 50.915 20.8112C51.0285 21.0496 51.1889 21.2346 51.3963 21.3661C51.6038 21.4976 51.8493 21.5634 52.133 21.5634C52.3263 21.5634 52.4956 21.5336 52.641 21.474C52.7881 21.4144 52.9113 21.3346 53.0106 21.2346C53.1117 21.1329 53.1879 21.0216 53.2393 20.9006L53.6888 21.0453C53.6267 21.2153 53.5248 21.3723 53.383 21.516C53.2429 21.6598 53.0674 21.7755 52.8564 21.8632C52.6472 21.9491 52.406 21.9921 52.133 21.9921Z"
      fill="white"
    />
    <path
      d="M56.4287 21.9921C56.0635 21.9921 55.7452 21.9018 55.4739 21.7212C55.2045 21.5406 54.9952 21.2934 54.8463 20.9795C54.6974 20.6657 54.6229 20.308 54.6229 19.9064C54.6229 19.5014 54.6983 19.1411 54.849 18.8255C55.0014 18.5099 55.2124 18.2626 55.4819 18.0838C55.7514 17.9032 56.0643 17.8129 56.4207 17.8129C56.6937 17.8129 56.9411 17.8655 57.1627 17.9707C57.3843 18.0742 57.5669 18.2206 57.7105 18.4099C57.8559 18.5975 57.9472 18.8167 57.9845 19.0674H57.5058C57.4561 18.8395 57.3364 18.6449 57.1467 18.4836C56.9588 18.3205 56.7194 18.239 56.4287 18.239C56.1681 18.239 55.9376 18.3091 55.7372 18.4494C55.5369 18.5879 55.38 18.7816 55.2665 19.0306C55.1548 19.2779 55.099 19.5645 55.099 19.8907C55.099 20.2185 55.1539 20.5087 55.2638 20.7612C55.3738 21.0119 55.528 21.2083 55.7266 21.3504C55.9269 21.4924 56.161 21.5634 56.4287 21.5634C56.6095 21.5634 56.7744 21.5301 56.9233 21.4634C57.074 21.3951 57.1999 21.2986 57.301 21.1741C57.4038 21.0496 57.473 20.9015 57.5084 20.7297H57.9871C57.9517 20.9734 57.8639 21.1908 57.7238 21.3819C57.5855 21.5713 57.4056 21.7203 57.184 21.829C56.9641 21.9377 56.7124 21.9921 56.4287 21.9921Z"
      fill="white"
    />
    <path
      d="M60.6532 21.9921C60.272 21.9921 59.9423 21.9044 59.6639 21.7291C59.3855 21.552 59.1701 21.3074 59.0177 20.9953C58.867 20.6814 58.7916 20.3202 58.7916 19.9117C58.7916 19.5049 58.867 19.1437 59.0177 18.8281C59.1701 18.5107 59.3802 18.2626 59.6479 18.0838C59.9174 17.9032 60.2286 17.8129 60.5814 17.8129C60.803 17.8129 61.0167 17.8532 61.2223 17.9339C61.428 18.0128 61.6124 18.1346 61.7755 18.2995C61.9404 18.4625 62.0707 18.6685 62.1664 18.9175C62.2622 19.1648 62.3101 19.4576 62.3101 19.796V20.0274H59.1187V19.6145H61.826C61.826 19.355 61.7728 19.1218 61.6665 18.9149C61.5619 18.7062 61.4156 18.5414 61.2277 18.4204C61.0415 18.2995 60.8261 18.239 60.5814 18.239C60.3226 18.239 60.0947 18.3074 59.8979 18.4441C59.7011 18.5809 59.5469 18.7615 59.4352 18.9859C59.3253 19.2103 59.2694 19.4558 59.2676 19.7223V19.9696C59.2676 20.2904 59.3235 20.571 59.4352 20.8112C59.5487 21.0496 59.7091 21.2346 59.9166 21.3661C60.124 21.4976 60.3695 21.5634 60.6532 21.5634C60.8465 21.5634 61.0158 21.5336 61.1612 21.474C61.3083 21.4144 61.4316 21.3346 61.5308 21.2346C61.6319 21.1329 61.7081 21.0216 61.7596 20.9006L62.209 21.0453C62.1469 21.2153 62.045 21.3723 61.9032 21.516C61.7631 21.6598 61.5876 21.7755 61.3766 21.8632C61.1674 21.9491 60.9263 21.9921 60.6532 21.9921Z"
      fill="white"
    />
    <path
      d="M63.8266 19.3831V21.9079H63.3532V17.8681H63.8133V18.502H63.8559C63.9516 18.2951 64.1005 18.1294 64.3026 18.0049C64.5065 17.8786 64.7583 17.8155 65.0579 17.8155C65.3327 17.8155 65.5739 17.8725 65.7813 17.9865C65.9905 18.0987 66.1527 18.2644 66.268 18.4836C66.385 18.7027 66.4435 18.9719 66.4435 19.291V21.9079H65.9701V19.3199C65.9701 18.9885 65.8762 18.7264 65.6882 18.5335C65.5021 18.3407 65.2521 18.2442 64.9383 18.2442C64.7237 18.2442 64.5331 18.2898 64.3665 18.381C64.1998 18.4722 64.0677 18.6037 63.9702 18.7755C63.8745 18.9456 63.8266 19.1481 63.8266 19.3831Z"
      fill="white"
    />
    <path
      d="M69.2991 17.8681V18.2758H67.3497V17.8681H69.2991ZM67.9587 16.9003H68.4348V20.8533C68.4348 21.0216 68.464 21.154 68.5225 21.2504C68.581 21.3451 68.6573 21.4126 68.7512 21.4529C68.8452 21.4915 68.9454 21.5108 69.0518 21.5108C69.1138 21.5108 69.167 21.5073 69.2113 21.5003C69.2557 21.4915 69.2947 21.4827 69.3283 21.474L69.4294 21.8974C69.3833 21.9149 69.3266 21.9307 69.2592 21.9448C69.1918 21.9605 69.1085 21.9684 69.0092 21.9684C68.8355 21.9684 68.6679 21.9307 68.5066 21.8553C68.347 21.7799 68.2158 21.6677 68.113 21.5187C68.0101 21.3696 67.9587 21.1847 67.9587 20.9637V16.9003Z"
      fill="white"
    />
    <path
      d="M70.3742 21.9079V17.8681H70.8343V18.4888H70.8688C70.9504 18.2854 71.0922 18.1215 71.2943 17.997C71.4982 17.8708 71.7287 17.8076 71.9858 17.8076C72.0248 17.8076 72.0682 17.8085 72.1161 17.8103C72.164 17.812 72.2039 17.8138 72.2358 17.8155V18.2916C72.2145 18.2881 72.1773 18.2828 72.1241 18.2758C72.0709 18.2688 72.0133 18.2653 71.9512 18.2653C71.7385 18.2653 71.5488 18.31 71.3821 18.3994C71.2172 18.4871 71.0869 18.6089 70.9912 18.765C70.8954 18.921 70.8476 19.099 70.8476 19.2989V21.9079H70.3742Z"
      fill="white"
    />
    <path
      d="M74.1845 22C73.9381 22 73.7129 21.9526 73.509 21.858C73.3051 21.7615 73.1429 21.623 73.0223 21.4424C72.9018 21.2601 72.8415 21.0391 72.8415 20.7796C72.8415 20.5797 72.8796 20.4114 72.9558 20.2747C73.0321 20.1379 73.1402 20.0257 73.2803 19.938C73.4204 19.8503 73.5861 19.7811 73.7776 19.7302C73.9691 19.6794 74.1801 19.6399 74.4106 19.6119C74.6393 19.5838 74.8325 19.5593 74.9903 19.5382C75.1499 19.5172 75.2713 19.4839 75.3547 19.4383C75.438 19.3927 75.4797 19.3191 75.4797 19.2174V19.1227C75.4797 18.8474 75.3963 18.6309 75.2297 18.473C75.0648 18.3135 74.8272 18.2337 74.5169 18.2337C74.2226 18.2337 73.9824 18.2977 73.7962 18.4257C73.6118 18.5537 73.4824 18.7045 73.4079 18.8781L72.9585 18.7176C73.0507 18.4967 73.1783 18.3205 73.3415 18.189C73.5046 18.0557 73.6872 17.9602 73.8893 17.9023C74.0914 17.8427 74.2962 17.8129 74.5036 17.8129C74.6597 17.8129 74.8219 17.8331 74.9903 17.8734C75.1605 17.9137 75.3183 17.9839 75.4637 18.0838C75.6091 18.182 75.727 18.3196 75.8174 18.4967C75.9078 18.6721 75.953 18.8947 75.953 19.1648V21.9079H75.4797V21.2688H75.4504C75.3937 21.3881 75.3094 21.5038 75.1977 21.616C75.0861 21.7282 74.946 21.8203 74.7776 21.8921C74.6091 21.964 74.4114 22 74.1845 22ZM74.2483 21.5713C74.5001 21.5713 74.7182 21.516 74.9026 21.4056C75.0869 21.2951 75.2288 21.1487 75.3281 20.9664C75.4291 20.7823 75.4797 20.5797 75.4797 20.3588V19.7749C75.4442 19.8083 75.3848 19.8381 75.3015 19.8644C75.2199 19.8907 75.1251 19.9143 75.0169 19.9354C74.9105 19.9547 74.8042 19.9713 74.6978 19.9853C74.5914 19.9994 74.4957 20.0116 74.4106 20.0222C74.1801 20.0502 73.9833 20.0941 73.8202 20.1537C73.657 20.2133 73.532 20.2957 73.4452 20.4009C73.3583 20.5043 73.3149 20.6376 73.3149 20.8007C73.3149 21.0461 73.4035 21.2364 73.5808 21.3714C73.7581 21.5047 73.9806 21.5713 74.2483 21.5713Z"
      fill="white"
    />
    <path d="M77.6857 16.5215V21.9079H77.2123V16.5215H77.6857Z" fill="white" />
    <path
      d="M78.9436 21.9079V17.8681H79.4196V21.9079H78.9436ZM79.1856 17.1738C79.0881 17.1738 79.0048 17.1414 78.9356 17.0765C78.8665 17.0098 78.8319 16.9301 78.8319 16.8371C78.8319 16.7442 78.8665 16.6653 78.9356 16.6004C79.0048 16.5338 79.0881 16.5005 79.1856 16.5005C79.2831 16.5005 79.3664 16.5338 79.4356 16.6004C79.5047 16.6653 79.5393 16.7442 79.5393 16.8371C79.5393 16.9301 79.5047 17.0098 79.4356 17.0765C79.3664 17.1414 79.2831 17.1738 79.1856 17.1738Z"
      fill="white"
    />
    <path
      d="M80.5439 21.9079V21.5371L82.9055 18.3442V18.31H80.645V17.8681H83.5145V18.2548L81.1955 21.4319V21.4661H83.5996V21.9079H80.5439Z"
      fill="white"
    />
    <path
      d="M86.1773 21.9921C85.7961 21.9921 85.4663 21.9044 85.188 21.7291C84.9096 21.552 84.6942 21.3074 84.5417 20.9953C84.391 20.6814 84.3157 20.3202 84.3157 19.9117C84.3157 19.5049 84.391 19.1437 84.5417 18.8281C84.6942 18.5107 84.9043 18.2626 85.172 18.0838C85.4415 17.9032 85.7527 17.8129 86.1055 17.8129C86.3271 17.8129 86.5407 17.8532 86.7464 17.9339C86.9521 18.0128 87.1365 18.1346 87.2996 18.2995C87.4645 18.4625 87.5948 18.6685 87.6905 18.9175C87.7863 19.1648 87.8341 19.4576 87.8341 19.796V20.0274H84.6428V19.6145H87.3501C87.3501 19.355 87.2969 19.1218 87.1905 18.9149C87.0859 18.7062 86.9397 18.5414 86.7517 18.4204C86.5656 18.2995 86.3502 18.239 86.1055 18.239C85.8466 18.239 85.6188 18.3074 85.422 18.4441C85.2252 18.5809 85.071 18.7615 84.9593 18.9859C84.8493 19.2103 84.7935 19.4558 84.7917 19.7223V19.9696C84.7917 20.2904 84.8476 20.571 84.9593 20.8112C85.0727 21.0496 85.2332 21.2346 85.4406 21.3661C85.6481 21.4976 85.8936 21.5634 86.1773 21.5634C86.3705 21.5634 86.5399 21.5336 86.6852 21.474C86.8324 21.4144 86.9556 21.3346 87.0549 21.2346C87.156 21.1329 87.2322 21.0216 87.2836 20.9006L87.7331 21.0453C87.671 21.2153 87.5691 21.3723 87.4272 21.516C87.2872 21.6598 87.1116 21.7755 86.9007 21.8632C86.6914 21.9491 86.4503 21.9921 86.1773 21.9921Z"
      fill="white"
    />
    <path
      d="M90.348 21.9921C90.0129 21.9921 89.7195 21.9044 89.4677 21.7291C89.2159 21.5537 89.0191 21.3091 88.8773 20.9953C88.7372 20.6814 88.6672 20.3159 88.6672 19.8986C88.6672 19.483 88.7372 19.1192 88.8773 18.8071C89.0191 18.4932 89.2168 18.2495 89.4704 18.0759C89.7239 17.9006 90.0191 17.8129 90.3559 17.8129C90.6042 17.8129 90.8107 17.8567 90.9756 17.9444C91.1405 18.0303 91.2726 18.1373 91.3719 18.2653C91.4711 18.3933 91.5483 18.5178 91.6032 18.6387H91.6458V16.5215H92.1218V21.9079H91.6591V21.1584H91.6032C91.5483 21.2811 91.4703 21.4073 91.3692 21.5371C91.2681 21.6651 91.1343 21.7729 90.9676 21.8606C90.8027 21.9483 90.5962 21.9921 90.348 21.9921ZM90.4038 21.5634C90.668 21.5634 90.8932 21.4924 91.0793 21.3504C91.2655 21.2066 91.4073 21.0093 91.5048 20.7586C91.6041 20.5061 91.6538 20.2168 91.6538 19.8907C91.6538 19.568 91.605 19.2831 91.5075 19.0359C91.41 18.7869 91.2681 18.5923 91.082 18.452C90.8958 18.31 90.6698 18.239 90.4038 18.239C90.1308 18.239 89.9003 18.3126 89.7124 18.4599C89.5262 18.6054 89.3844 18.8027 89.2869 19.0517C89.1911 19.3006 89.1432 19.5803 89.1432 19.8907C89.1432 20.2045 89.192 20.4877 89.2895 20.7402C89.387 20.9927 89.5289 21.1934 89.715 21.3425C89.903 21.4897 90.1326 21.5634 90.4038 21.5634Z"
      fill="white"
    />
    <path
      d="M95.6748 21.9079V16.5215H97.4327C97.8192 16.5215 98.1419 16.5952 98.4008 16.7425C98.6614 16.888 98.8573 17.0861 98.9885 17.3369C99.1215 17.5876 99.188 17.8699 99.188 18.1837C99.188 18.4976 99.1224 18.7808 98.9912 19.0333C98.86 19.284 98.665 19.483 98.4061 19.6303C98.1472 19.7758 97.8255 19.8486 97.4407 19.8486H96.0578V19.4067H97.4274C97.7129 19.4067 97.9496 19.3541 98.1375 19.2489C98.3254 19.142 98.4655 18.9964 98.5577 18.8123C98.6517 18.6282 98.6986 18.4187 98.6986 18.1837C98.6986 17.9488 98.6517 17.7393 98.5577 17.5552C98.4655 17.371 98.3245 17.2264 98.1348 17.1212C97.9469 17.016 97.7084 16.9634 97.4194 16.9634H96.1722V21.9079H95.6748Z"
      fill="white"
    />
    <path
      d="M101.801 21.9921C101.448 21.9921 101.136 21.9035 100.865 21.7265C100.595 21.5494 100.384 21.3048 100.232 20.9927C100.079 20.6788 100.003 20.3167 100.003 19.9064C100.003 19.4926 100.079 19.1288 100.232 18.815C100.384 18.4993 100.595 18.2539 100.865 18.0785C101.136 17.9014 101.448 17.8129 101.801 17.8129C102.154 17.8129 102.465 17.9014 102.734 18.0785C103.004 18.2556 103.215 18.5011 103.367 18.815C103.522 19.1288 103.599 19.4926 103.599 19.9064C103.599 20.3167 103.522 20.6788 103.37 20.9927C103.217 21.3048 103.006 21.5494 102.734 21.7265C102.465 21.9035 102.154 21.9921 101.801 21.9921ZM101.801 21.5634C102.085 21.5634 102.325 21.488 102.522 21.3372C102.718 21.1864 102.867 20.9856 102.968 20.7349C103.071 20.4842 103.123 20.208 103.123 19.9064C103.123 19.6049 103.071 19.3278 102.968 19.0753C102.867 18.8228 102.718 18.6203 102.522 18.4678C102.325 18.3152 102.085 18.239 101.801 18.239C101.519 18.239 101.279 18.3152 101.08 18.4678C100.883 18.6203 100.734 18.8228 100.631 19.0753C100.53 19.3278 100.479 19.6049 100.479 19.9064C100.479 20.208 100.53 20.4842 100.631 20.7349C100.734 20.9856 100.883 21.1864 101.08 21.3372C101.277 21.488 101.517 21.5634 101.801 21.5634Z"
      fill="white"
    />
    <path
      d="M106.226 21.9921C105.873 21.9921 105.561 21.9035 105.289 21.7265C105.02 21.5494 104.809 21.3048 104.656 20.9927C104.504 20.6788 104.428 20.3167 104.428 19.9064C104.428 19.4926 104.504 19.1288 104.656 18.815C104.809 18.4993 105.02 18.2539 105.289 18.0785C105.561 17.9014 105.873 17.8129 106.226 17.8129C106.578 17.8129 106.89 17.9014 107.159 18.0785C107.429 18.2556 107.639 18.5011 107.792 18.815C107.946 19.1288 108.023 19.4926 108.023 19.9064C108.023 20.3167 107.947 20.6788 107.795 20.9927C107.642 21.3048 107.43 21.5494 107.159 21.7265C106.89 21.9035 106.578 21.9921 106.226 21.9921ZM106.226 21.5634C106.509 21.5634 106.749 21.488 106.946 21.3372C107.143 21.1864 107.292 20.9856 107.393 20.7349C107.496 20.4842 107.547 20.208 107.547 19.9064C107.547 19.6049 107.496 19.3278 107.393 19.0753C107.292 18.8228 107.143 18.6203 106.946 18.4678C106.749 18.3152 106.509 18.239 106.226 18.239C105.944 18.239 105.703 18.3152 105.505 18.4678C105.308 18.6203 105.158 18.8228 105.055 19.0753C104.954 19.3278 104.904 19.6049 104.904 19.9064C104.904 20.208 104.954 20.4842 105.055 20.7349C105.158 20.9856 105.308 21.1864 105.505 21.3372C105.702 21.488 105.942 21.5634 106.226 21.5634Z"
      fill="white"
    />
    <path d="M109.536 16.5215V21.9079H109.063V16.5215H109.536Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.6606 16.6159C19.0081 19.4533 16.128 21.4898 12.7467 22V9.55612C15.6793 10.274 17.681 12.4045 20.6606 16.6159ZM22 9.20394C13.8133 3.7208 11.9402 4.52541 1.27884 10.1913C1.10109 10.2858 1.20004 10.5581 1.39714 10.5172C4.38529 9.89712 6.74142 9.47425 8.69093 9.32751V21.8673C3.72008 20.7772 0 16.3536 0 11.0623C0 4.95278 4.95971 0 11.0778 0C16.5617 0 21.115 3.9792 22 9.20394Z"
      fill="#0070E4"
    />
  </svg>
);
