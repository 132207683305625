export const faqConfig = [
  {
    title: "Distribution of rewards for the found block in the BTC blockchain?",
    description:
      "Owners of miners working in the network, in proportion to their participation in the PPLNS system with a window length of 7 days - 98% Network, Development and Community Foundation - 1% The owner of the NOD (POOL) on which the block was found - 0,5% The owner of a NOD whose template has become a block in the BTC blockchain - 0,25% The owner of the miner who found the block - 0,25%",
  },
  {
    title: "What is a block?",
    description:
      "This is a fragment of the Bitcoin chain that meets the specific requirements of the network. Successfully finding a block means that the pool receives a reward, which it distributes among users.",
  },
  {
    title: "How are the charges going?",
    description:
      "In the accrual system we use, we pay in real time for each decision received from users, this approach is called Pay-Per-Share (PPS).",
  },
  {
    title:
      "Will your code be open to the community like all decentralized projects?",
    description: "Yes",
  },
  {
    title: "On what stack is the pool written?",
    description: "NodeJS and C++",
  },
  {
    title:
      "Have performance tests and stress tests been carried out for failure of the network stability, can you see the results?",
    description:
      "A network of 8 nodes consistently processed from 1 to 20 decisions per minute. Based on the results of tests that lasted a month, an estimate of the growth rate of the chain was obtained ~ 90 gigabytes of data per year. Performance when synchronizing a new node, in the absence of an emphasis on network infrastructure, allows you to synchronize a new node within 3.5 hours. According to these results, the limiter will be the physical bandwidth of the data links between the nodes, and not the software or hardware resources of the node itself.",
  },
  {
    title: "What equipment is required for mining?",
    description:
      "Any equipment that supports the stratum protocol. To use direct payments through a coinbase transaction, hardware is required that has no limits on the size of a coinbase transaction. If direct payments are not required, then equipment with such restrictions can also be used.",
  },
  {
    title: "How is ddos ​​protection built?",
    description:
      'The principle of "from quantity to quality". With a large number of nodes, such attacks on the network become either technically unfeasible or economically inefficient.',
  },
  {
    title:
      "What is the principle of the p2p network you implemented, what is its architecture?",
    description:
      "A normal peer-to-peer network with direct data exchange between participants.",
  },
  {
    title:
      "What is the role of the master node and how is the protection against the scam of these nodes implemented?",
    description:
      "There is no master node on the network, this is a simplification of the concept. There are nodes that have the right to generate templates for new blocks, their role is to validate the transactions that will be included in the new pool block. They do not have any financial role, and the enforcement of honesty is carried out by revoking the certificate of the node, carried out in case of untrustworthy behavior. The node's certificate or certificate revocation is signed by us, as the project's maintainers, and sent over the p2p network as a broadcast message.",
  },
  {
    title: "Can you describe in more detail how the PPLNS calculator works?",
    description:
      "Just like it works on centralized pools. A sample of decisions for the requested period is taken, and the calculation of the distribution of the reward over it is made, by a simple proportion.",
  },
  {
    title:
      "There is already a decentralized pool P2Pool, how does your project differ in technical and economic terms?",
    description:
      "It differs in that the P2Pool project cannot be used in real conditions. If you need to list the reasons for this, it will be long, but in fact P2Pool is not a working solution, it is a proof-of-concept and nothing more.",
  },
];
