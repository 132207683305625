// http://80.249.131.181:8888/dailyChart
export default [
    {
        "sliceTime": 1684733164,
        "sliceSnapshots": 2831,
        "sliceBytes": 193616391,
        "sliceWork": "69236502907",
        "sliceDuration": 86416,
        "hashRate": "3441127981797050"
    },
    {
        "sliceTime": 1684646748,
        "sliceSnapshots": 2837,
        "sliceBytes": 185776391,
        "sliceWork": "5252880448",
        "sliceDuration": 86413,
        "hashRate": "261082820107597"
    },
    {
        "sliceTime": 1684560335,
        "sliceSnapshots": 2848,
        "sliceBytes": 187227955,
        "sliceWork": "4934225431",
        "sliceDuration": 86423,
        "hashRate": "245216399074742"
    },
    {
        "sliceTime": 1684473912,
        "sliceSnapshots": 2847,
        "sliceBytes": 199931943,
        "sliceWork": "4993156556",
        "sliceDuration": 86401,
        "hashRate": "248208285920625"
    },
    {
        "sliceTime": 1684387511,
        "sliceSnapshots": 2922,
        "sliceBytes": 207514459,
        "sliceWork": "4943553941",
        "sliceDuration": 86411,
        "hashRate": "245714116288515"
    },
    {
        "sliceTime": 1684301100,
        "sliceSnapshots": 2815,
        "sliceBytes": 210474230,
        "sliceWork": "4918528117",
        "sliceDuration": 86420,
        "hashRate": "244444774438457"
    },
    {
        "sliceTime": 1684214680,
        "sliceSnapshots": 2471,
        "sliceBytes": 204029864,
        "sliceWork": "4831326660",
        "sliceDuration": 86430,
        "hashRate": "240083188719112"
    }
]