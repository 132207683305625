import { FC, useEffect, useState } from "react";
import { Advantages } from "./components/advantagesBlock";
import { AboutBlock } from "./components/aboutBlock";
import { TitleBlock } from "./components/titleBlock";
import { JoinToPoolBlock } from "./components/joinToPoolBlock";
import { FaqBlock } from "./components/faqBlock";
import { Statistic } from "./components/statisctic";
import { Nodes } from "./components/nodes/Nodes";
import { Helmet } from "react-helmet-async";
import { Shares } from "./components/nodes/types";

export const Main: FC = () => {
  const [connected, setConnected] = useState(false);
  const [connected2, setConnected2] = useState(false);
  const [shares, setShares] = useState<Shares[]>([]);
  const [dailyChart, setDailyChart] = useState<any>(undefined);
  const [hourlyChart, setHourlyChart] = useState();
  const [general, setGeneral] = useState();
  const [pplns, setPplns] = useState();

  // const w2 = new WebSocket("ws://80.249.131.181:7000");
  // const ws = new WebSocket("ws://80.249.131.181:8888");
  // ws.onopen = () => {
  //   setConnected(true);
  // };

  // ws.onerror = (e) => {
  //   console.log(e, "error");
  // };

  // w2.onopen = () => {
  //   setConnected2(true);
  // };

  // w2.onerror = (e) => {
  //   console.log(e, "error");
  // };
  // const getSnapshot = (data: string) => {
  //   const message = {
  //     id: 1,
  //     page: "snapshot",
  //     section: data,
  //   };
  //   try {
  //     ws.send(JSON.stringify(message));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getDailyChart = () => {
  //   const message = {
  //     id: 2,
  //     page: "dailyChart",
  //   };
  //   try {
  //     ws.send(JSON.stringify(message));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getHourlyChart = () => {
  //   const message = {
  //     id: 3,
  //     page: "hourlyChart",
  //   };
  //   try {
  //     ws.send(JSON.stringify(message));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getGeneral = () => {
  //   const message = {
  //     id: 4,
  //     page: "general",
  //   };
  //   try {
  //     ws.send(JSON.stringify(message));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const getPplns = () => {
  //   const message = {
  //     id: 5,
  //     page: "pplns",
  //   };
  //   try {
  //     ws.send(JSON.stringify(message));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (connected) {
  //     ws.onmessage = (evt) => {
  //       const message = JSON.parse(evt.data);

  //       if (message?.id === 1) setShares(message?.result?.Shares);
  //       if (message?.id === 2 && message?.result.length)
  //         setDailyChart(message?.result);
  //       if (message?.id === 3) setHourlyChart(message?.result);
  //       if (message?.id === 4) setGeneral(message?.result);
  //       if (message?.id === 5) setPplns(message?.result);

  //       if (message?.new_top) {
  //         getSnapshot(message?.new_top);
  //         getDailyChart();
  //         getPplns();
  //         getGeneral();
  //         getHourlyChart();
  //       }
  //     };
  //   }
  // }, [connected]);

  return (
    <>
      <Helmet>
        <title>SOLOMINE - BTC POOL</title>
      </Helmet>
      <TitleBlock />
      <AboutBlock />
      <Advantages />
      <Statistic
        //  @ts-ignore
        blockHeight={pplns?.blockHeight}
        //  @ts-ignore
        blockDifficulty={general?.bitcoin?.blockDifficulty}
        dailyChart={dailyChart}
      />
      <Nodes hourlyChart={hourlyChart} shares={shares} />
      <JoinToPoolBlock />
      <FaqBlock />
    </>
  );
};
