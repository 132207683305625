import { Dispatch, FC, SetStateAction, useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../../constants/colors";
import useResize from "../../../hooks/useResize";
import { Button } from "../../buttons";
import { configMenu, ROUTES } from "../../../config";
import { Link, useLocation } from "react-router-dom";

type MenuProps = {
  handleOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
};

export const Menu: FC<MenuProps> = ({ handleOpen, open }) => {
  const { width } = useResize();
  const location = useLocation();
  useEffect(() => {
    const hash = location.hash;
    const el = hash && document.getElementById(hash);
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <MenuStyle open={open}>
      <ButtonClose onClick={() => handleOpen(false)} />
      {configMenu.map((item) => (
        <Item
          className={
            location.pathname === item.link 
              ? "active"
              : ""
          }
          to={`${item.link}${item.hash ? item.hash : ""}`}
          key={item.label}
        >
          {item.label}
        </Item>
      ))}
      <ButtonWrapper>
        <Link to={ROUTES.JOIN}>
          <Button
            fullWidth
            size={width && width < 1440 && width > 767 ? "medium" : "small"}
          >
            Join to Pool
          </Button>
        </Link>
      </ButtonWrapper>
    </MenuStyle>
  );
};

const MenuStyle = styled.div<{ open: boolean }>`
  width: 262px;
  padding: 36px 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 40px;
  background: ${colors.white};
  box-shadow: ${colors.shadow};
  border-radius: 0px 16px 16px 0px;
  position: fixed;
  top: 4px;
  left: ${({ open }) => (open ? "0" : "-100%")};
  min-height: calc(100vh - 8px);
  max-height: 100%;
  overflow-y: auto;
  transition: left 0.3s ease-in;
  z-index: 3;
  @media (min-width: 767px) {
    width: 445px;
    left: ${({ open }) => (open ? "0" : "-100%")};
  }
  @media (min-width: 1440px) {
    position: static;
    background: transparent;
    flex-direction: row;
    width: auto;
    padding: 0;
    box-shadow: none;
    min-height: auto;
  }
`;
const Item = styled(Link)`
  width: 100%;
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 16px 24px;
  border-radius: 32px;
  transition: all 0.3s;
  &.active {
    background: linear-gradient(90deg, #4e555b 0%, #767d83 100%), #0070e4;
    color: ${colors.white};
  }
  @media (min-width: 1440px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    &.active {
      background: transparent;
      color: ${colors.dark};
    }
  }
`;
const ButtonWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  flex: none;
  @media (min-width: 1440px) {
    margin-top: 0;
    width: auto;
  }
`;
const ButtonClose = styled.div`
  height: 20px;
  width: 20px;
  position: relative;
  margin-left: auto;
  margin-right: 4px;
  &:after,
  &:before {
    display: block;
    position: absolute;
    content: "";
    background: ${colors.dark2};
    width: 24px;
    height: 2px;
    top: 7px;
    left: -2px;
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
  @media (min-width: 1440px) {
    display: none;
  }
`;
