// http://80.249.131.181:7000/general
export default {
    "content": {
        "bestShare": 949011015171,
        "miners": [
            "3LnEAZj28gLnPTAnnCFN8uT2bcKcuVQdQU",
            "32KEDigEXzU6NNA1DMEeeaUCcA1P7Aji8w"
        ],
        "totalWork1H": "2057184450",
        "totalWork1D": "66211236469",
        "hashRate1H": "2454316648497152",
        "hashRate1D": "3291378417385156"
    },
    "uptime": 50136,
    "servedJobs": 11504
}