import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";
import { Container } from "../layout";
import { LogoIcon } from "../icons";
import { Menu } from "./components/Menu";
import { Link } from "react-router-dom";
import { ROUTES } from "../../config";

export const Header: FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    if (menuOpen) document.body.style.overflow = "hidden";

    if (!menuOpen) document.body.style.overflow = "visible";
  }, [menuOpen]);
  return (
    <>
      <Wrapper>
        <Container>
          <Inner>
            <Link to={ROUTES.HOME}>
              <LogoIcon />
            </Link>
            <MenuDesc>
              <Menu open={menuOpen} handleOpen={setMenuOpen} />
            </MenuDesc>
            <ButtonOpen open={menuOpen} onClick={() => setMenuOpen(true)}>
              <span />
            </ButtonOpen>
          </Inner>
        </Container>
      </Wrapper>
      <Overlay onClick={() => setMenuOpen(false)} open={menuOpen} />
      <MenuMob>
        <Menu open={menuOpen} handleOpen={setMenuOpen} />
      </MenuMob>
    </>
  );
};

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 16px;
  width: 100%;
  z-index: 1;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 100%;
  z-index: 1;
  border-radius: 32px 32px;
  background: ${colors.alpha5};
  backdrop-filter: blur(6px);
  @media (min-width: 767px) {
    padding: 17px 24px;
  }
  @media (min-width: 1440px) {
    padding: 21px 12px;
  }
`;

const ButtonOpen = styled.div<{ open: boolean }>`
  position: relative;
  height: 20px;
  width: 25px;
  & span {
    display: block;
    width: 100%;
    height: 2px;
    background: ${({ open }) => (open ? colors.white : colors.dark)};
    margin-top: 9px;
  }

  &:before,
  &:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background: ${({ open }) => (open ? colors.white : colors.dark)};
  }

  &:after {
    top: 0;
  }

  &:before {
    bottom: 0;
  }
  @media (min-width: 1440px) {
    display: none;
  }
`;
const Overlay = styled.div<{ open: boolean }>`
  background: ${colors.overlay};
  z-index: 2;
  pointer-events: ${({ open }) => (open ? "all" : "none")};
  width: 200vw;
  height: 200vh;
  top: 0;
  left: -100px;
  right: -100px;
  bottom: 0;
  opacity: ${({ open }) => (open ? 1 : 0)};
  transition: opacity 0.2s;
  position: fixed;
  @media (min-width: 767px) {
    left: -110px;
  }
  @media (min-width: 1440px) {
    display: none;
  }
`;
const MenuMob = styled.div`
  @media (min-width: 1440px) {
    display: none;
  }
`;
const MenuDesc = styled.div`
  display: none;
  @media (min-width: 1440px) {
    display: block;
  }
`;
