import { FC, useState } from "react";
import styled from "styled-components";
import { TitleBlock } from "../../components/titleBlock";
import Collapsible from "react-collapsible";
import { faqConfig } from "./config";
import { colors } from "../../constants/colors";
import { ARROW } from "../../constants/img";
import { Helmet } from "react-helmet-async";

export const FAQ: FC = () => {
  const [current, setCurrent] = useState<number | null>(null);
  return (
    <>
      <Helmet>
        <title>SOLOMINE - FAQ</title>
      </Helmet>
      <Wrapper>
        <TitleBlock
          title="FAQ"
          description="Here you can find answers to popular questions"
        />
        <Content>
          {faqConfig.map((faq, i) => (
            <Collapsible
              onOpening={() => setCurrent(i)}
              onClosing={() => setCurrent(null)}
              key={i}
              trigger={
                <Head>
                  {faq.title} <Arrow open={current === i} src={ARROW} alt="" />
                </Head>
              }
            >
              <Text>{faq.description}</Text>
            </Collapsible>
          ))}
        </Content>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div``;
const Content = styled.div`
  padding: 32px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (min-width: 767px) {
    padding: 32px 40px;
  }
  @media (min-width: 1440px) {
    padding: 32px 116px;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
  background: rgba(10, 10, 10, 0.03);
  border-radius: 16px;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${colors.dark};
  cursor: pointer;
`;
const Text = styled(Head)`
  margin-top: 12px;
  background: rgba(254, 254, 254, 0.7);
  cursor: default;
`;
const Arrow = styled.img<{ open: boolean }>`
  display: block;
  position: relative;
  transition: all 0.3s;
  transform: ${({ open }) => (open ? "rotateX(180deg)" : "")};
`;
