export const ROUTES = {
  HOME: "/",
  ABOUT: "/about",
  FAQ: "/faq",
  JOIN: "/join",
  STATISTICS: "#statistic",
};

export const configMenu = [
  { label: "About", link: ROUTES.ABOUT },
  { label: "Statistics", link: ROUTES.HOME, hash: ROUTES.STATISTICS },
  { label: "FAQ", link: ROUTES.FAQ },
];
