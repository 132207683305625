import { FC, ReactNode } from "react";
import styled from "styled-components";
import { colors } from "../../constants/colors";

export const CardWhite: FC<{ children: ReactNode; title: string }> = ({
  children,
  title,
}) => (
  <Warpper>
    <Card>
      <Title>{title}</Title>
      {children}
    </Card>
  </Warpper>
);

const Warpper = styled.div`
  margin-top: 16px;
  padding: 0 8px;
  @media (min-width: 767px) {
    padding: 0 16px;
  }
`;

const Card = styled.div`
  padding: 24px 12px;
  background: ${colors.light};
  border-radius: 32px;
  padding: 32px 24px;
  @media (min-width: 767px) {
    padding: 32px 24px;
  }
  @media (min-width: 1440px) {
    padding: 32px 88px;
  }
`;
const Title = styled.div`
  margin-bottom: 16px;
  font-weight: 700;
  font-size: 30px;
  line-height: 150%;
  color: ${colors.dark};
  @media (min-width: 767px) {
    font-size: 28px;
  }
  @media (min-width: 1440px) {
    font-size: 32px;
    line-height: 44px;
  }
`;
